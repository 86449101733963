<template>
    <!--c系统退款退货详情-->
    <div class="SystemRefundDeliveryDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    size="small"
                    label-width="85px"
                >
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="售后单号">
                                <el-input v-model="form.refundNo" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单编码">
                                <el-input v-model="form.orderBizCode" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单号">
                                <el-input v-model="form.orderNo" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="会员名称">
                                <el-input v-model="form.memberName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="创建时间">
                                <el-date-picker v-model="form.createTime" value-format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="退还金额">
                                <el-input :value="form.refundMoney | money" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label-width="100px" label="支付类型名称">
                                <el-input v-model="form.refundPayTypeName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="配送人">
                                <el-input v-model="form.orderDeliveryerName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label-width="120px" label="商品应退金额">
                                <el-input :value="form.goodsRefundMoney | money" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-card style="margin-top: 8px">
                    <el-table
                        id="printMe"
                        border
                        stripe
                        style="width: 100%"
                        :data="tableData"
                        size="mini"
                        :highlight-current-row="true"
                        max-height="500"
                    >
                        <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                        <el-table-column prop="skuCode" label="sku编码" width="120" />
                        <el-table-column prop="goodsName" label="商品名称" width="120" />
                        <el-table-column prop="bizCode" label="业务编码" width="120" />
                        <el-table-column prop="bar" label="商品条码" width="120" />
                        <el-table-column prop="count" label="数量" width="120" />

                        <el-table-column label="单商品实际退还价格" width="120" prop="refundPrice">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.refundPrice | money }}</span></template
                            >
                        </el-table-column>
                        <el-table-column label="退还金额" width="120" prop="money">
                            <template slot-scope="scope"
                                ><span>{{ (scope.row.refundPrice * scope.row.count) | money }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import MoneyUtils from 'js/MoneyUtils';

export default {
    name: 'SystemOrderDeliveryDetail',
    props: ['form'],
    data() {
        return {
            url: {
                querySystemOrderDeliveryDetail: '/fromSystemc/refund/refundDeliveryDetail',
            },
            meta: {
                types: [],
            },
            codes: [],
            tableData: [],
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: { code: _this.form.code } };
            Util.queryTable(_this, _this.url.querySystemOrderDeliveryDetail, _params, (data) => {
                _this.tableData = data.data;
            });
        },
    },
    filters: {
        alreadyCreateTradeFlag(alreadyCreateTradeFlag) {
            if (typeof alreadyCreateTradeFlag == 'undefined') {
                return '';
            }
            if (alreadyCreateTradeFlag == true) {
                return '已创建';
            }
            if (alreadyCreateTradeFlag == false) {
                return '未创建';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
